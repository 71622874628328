import { useEffect } from 'react';
import { Crisp } from 'crisp-sdk-web';
import { useLocation } from 'react-router-dom';
import { REACT_APP_API_CRISP_WEBSITE_ID } from '../../../config/envConfig';

const CrispHandler = () => {
  const location = useLocation();

  Crisp.configure(REACT_APP_API_CRISP_WEBSITE_ID, {
    autoload: false,
  });

  Crisp.load();
  Crisp.setZIndex(99999);
  Crisp.setSafeMode(false);

  useEffect(() => {
    const match = /^\/project\/[^/]+$/.test(location.pathname);

    if (!match) {
      Crisp.setTokenId(null);
      Crisp.session.reset();
      Crisp.chat.hide();
      // console.log('hiding from component');
    } else {
      // console.log('showing from component');
      // Crisp.chat.show();
    }
  }, [location.pathname]);

  return null;
};

export default CrispHandler;
